import {
  Checkbox,
  Subtitle2,
  Input,
  Title1,
  Label,
  Slider,
  Caption1Strong,
} from "@fluentui/react-components";
import moment from "moment/moment";
import React, { useState } from "react";

export default function RequiredChoices(props) {
  const [changeType, setChangeType] = useState([]);
  const [changeTypeString, setChangeTypeString] = useState("");

  const [notificationUrl, setNotificationUrl] = useState("");

  const expirationTimeMin = 1;
  const expirationTimeMax = props.selectedItem.expiryMins;
  const [calculatedExpiryTime, setCalculatedExpiryTime] = useState(
    moment().add(expirationTimeMin, "mins").calendar()
  );
  const [utcExpiryTime, setUtcExpiryTime] = useState(
    moment().add(expirationTimeMin, "mins").utc().format("YYYY-MM-DDTHH:mm:ssZ")
  );
  const [calculatedExpiryLength, setCalculatedExpiryLength] = useState(
    moment.duration(expirationTimeMin, "minutes").humanize()
  );

  const supportedChangeTypeValues = [
    { Name: "Created", Value: "created" },
    { Name: "Updated", Value: "updated" },
    { Name: "Deleted", Value: "deleted" },
  ];

  const ValidateRequiredChoices = (
    theChangeTypeString,
    theCalculatedExpiryTime,
    theNotificationUrl
  ) => {
    if (theChangeTypeString.length === 0 || theNotificationUrl.length === 0) {
      props.onRequiredChoicesUpdated(null);
      return;
    }

    let requiredChoices = {
      changeType: theChangeTypeString,
      expirationTime: theCalculatedExpiryTime,
      notificationUrl: theNotificationUrl,
    };
    console.log("Change");
    props.onRequiredChoicesUpdated(requiredChoices);
  };

  const onChangeTypeChange = (event, data) => {
    //let localChangeType = changeType;
    if (event.target.checked) {
      changeType.push(event.target.value);
    } else {
      const index = changeType.indexOf(event.target.value);
      if (index > -1) {
        changeType.splice(index, 1);
      }
    }
    setChangeType(changeType);
    setChangeTypeString(changeType.toString());
    ValidateRequiredChoices(
      changeType.toString(),
      utcExpiryTime,
      notificationUrl
    );
  };

  return (
    <>
      <div className="centeredLeftJustifyContent">
        <Title1 block>Required</Title1>
        <Subtitle2 block>
          <span style={{ color: "red" }}>*</span> Notifiy me when items are...
        </Subtitle2>
        <div>
          {supportedChangeTypeValues.map((item) => (
            <Checkbox
              key={item.Value}
              label={item.Name}
              value={item.Value}
              onChange={onChangeTypeChange}
            />
          ))}
        </div>

        <Subtitle2 block>
          <span style={{ color: "red" }}>*</span> Expiration Time
        </Subtitle2>
        <div>
          <Label aria-hidden>{expirationTimeMin}</Label>
          <Slider
            min={expirationTimeMin}
            max={expirationTimeMax}
            defaultValue={20}
            onChange={(event, data) => {
              let calculatedExpiryTime = moment()
                .add(data.value, "minutes")
                .calendar();
              setCalculatedExpiryTime(calculatedExpiryTime);
              let utcExpiryTime = moment()
                .add(data.value, "minutes")
                .utc()
                .format("YYYY-MM-DDTHH:mm:ssZ");
              setUtcExpiryTime(utcExpiryTime);
              setCalculatedExpiryLength(
                moment.duration(data.value, "minutes").humanize()
              );
              ValidateRequiredChoices(
                changeTypeString,
                utcExpiryTime,
                notificationUrl
              );
            }}
          />
          <Label aria-hidden>
            {expirationTimeMax} minutes (
            {moment.duration(expirationTimeMax, "minutes").humanize()})
          </Label>
          <div style={{ display: "grid" }}>
            <Caption1Strong>{utcExpiryTime}</Caption1Strong>
            <Caption1Strong>
              About {calculatedExpiryLength} - {calculatedExpiryTime}
            </Caption1Strong>
          </div>
        </div>

        <Subtitle2 block>
          <span style={{ color: "red" }}>*</span> Notification URL
        </Subtitle2>

        <div>
          <Input
            type="url"
            className="requiredField"
            placeholder="The URL where change notifications should be sent."
            value={notificationUrl}
            onChange={(event) => {
              setNotificationUrl(event.target.value);
              ValidateRequiredChoices(
                changeTypeString,
                utcExpiryTime,
                event.target.value
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
