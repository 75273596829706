import React from "react";
import {
  Dropdown,
  makeStyles,
  Option,
  OptionGroup,
  shorthands,
  Switch,
  Tooltip,
} from "@fluentui/react-components";
import { Beaker24Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    // maxWidth: "400px",
  },
});

export default function OptionPicker(props) {
  //   useEffect(() => {
  //     console.log(options.options);
  //   }, []);

  const [includeBetaOnly, setIncludeBetaOnly] = React.useState(false);
  const filteredData = props.options.filter(
    (item) => item.isInV1 === true || includeBetaOnly
  );
  const categories = [...new Set(filteredData.map((item) => item.category))];

  const GetItemsForCategory = (category) => {
    return filteredData.filter((item) => item.category === category);
  };
  const onNotificationSelected = (event, data) => {
    props.notificationSelectedCallback(data);
  };
  const styles = useStyles();

  return (
    <>
      <div className={styles.root}>
        <Switch
          label="Include /beta endpoint resources"
          value={includeBetaOnly}
          onChange={(event) => {
            setIncludeBetaOnly(event.target.checked);
          }}
        />
        <Dropdown
          placeholder="Start by choosing a resource to build a notification subscription for"
          onOptionSelect={onNotificationSelected}
          size="large"
          style={{ width: "40vw" }}
        >
          {categories.map((category) => (
            <OptionGroup key={category} label={category}>
              {GetItemsForCategory(category).map((item) => (
                <Option
                  key={item.format}
                  value={item.format}
                  text={item.displayName}
                >
                  {!item.isInV1 && (
                    <Tooltip content="This is a beta resource">
                      <Beaker24Regular aria-label="This is a beta resource" />
                    </Tooltip>
                  )}
                  {item.parent} - {item.displayName}
                </Option>
              ))}
            </OptionGroup>
          ))}
        </Dropdown>
      </div>
    </>
  );
}
