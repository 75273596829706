import {
  Checkbox,
  Subtitle2,
  Input,
  Textarea,
  Dropdown,
  Option,
  Caption1Strong,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { InfoLabel } from "@fluentui/react-components/unstable";

export default function OptionalChoices(props) {
  const [includeResourceData, setIncludeResourceData] = useState(false);
  const [encryptionCertificate, setEncryptionCertificate] = useState("");
  const [encryptionCertificateId, setEncryptionCertificateId] = useState("");
  const [supportedTLSVersion, setSupportedTLSVersion] = useState("");
  const [notificationQueryOptions, setNotificationQueryOptions] = useState([]);
  const [lifecycleNotificationUrl, setLifecycleNotificationUrl] = useState("");
  const [clientState, setClientState] = useState("");
  const supportedTLSOptions = [
    { name: "default", value: "" },
    { name: "1.0", value: "v1_0" },
    { name: "1.1", value: "v1_1" },
    { name: "1.2", value: "v1_2" },
    { name: "1.3", value: "v1_3" },
  ];

  const ValidateOptionalChoices = (
    theIncludeResourceData,
    theEncryptionCertificate,
    theEncryptionCertificateId,
    theSupportedTLSVersion,
    theNotificationQueryOptions,
    theLifecycleNotificationUrl,
    theClientState
  ) => {
    if (!theIncludeResourceData) {
      theEncryptionCertificate = "";
      theEncryptionCertificateId = "";
    }

    let optionalChoices = {
      includeResourceData: theIncludeResourceData,
      encryptionCertificate: theEncryptionCertificate,
      encryptionCertificateId: theEncryptionCertificateId,
      supportedTLSVersion: theSupportedTLSVersion,
      notificationQueryOptions: theNotificationQueryOptions,
      lifecycleNotificationUrl: theLifecycleNotificationUrl,
      clientState: theClientState,
    };

    props.onOptionalChoicesUpdated(optionalChoices);
  };

  return (
    <>
      <div>
        <Subtitle2 block>
          <InfoLabel
            info={
              <p>
                The client state is an opaque string value used to maintain
                state between the request and the notification. The client can
                check that the change notification came from the service by
                comparing the value of the clientState property sent with the
                subscription with the value of the clientState property received
                with each change notification.
              </p>
            }
          >
            Client State
          </InfoLabel>
        </Subtitle2>
        <div>
          <Caption1Strong>
            According to{" "}
            <a
              href="https://learn.microsoft.com/en-us/graph/api/resources/subscription?view=graph-rest-1.0#properties"
              target="_blank"
              rel="noreferrer"
            >
              the documentation
            </a>
            , clientState is a <i>required</i> field. However in practice this
            requirement is no longer enforced and the documentation is
            incorrect.
          </Caption1Strong>
        </div>
        <div>
          <Input
            className="optionalField"
            placeholder="A unique value you can check later."
            value={clientState}
            onChange={(event) => {
              setClientState(event.target.value);

              ValidateOptionalChoices(
                includeResourceData,
                encryptionCertificate,
                encryptionCertificateId,
                supportedTLSVersion,
                notificationQueryOptions,
                lifecycleNotificationUrl,
                event.target.value
              );
            }}
          />
        </div>

        <Subtitle2 block>
          {" "}
          <InfoLabel
            info={
              <p>
                By default, Graph will include a URL to the data that has
                triggered the notification. With this option set, Graph will
                include the actual data in the notification, negating the need
                to make an another Graph call. If this is set, a certificate and
                certificate ID is required. For more information, see{" "}
                <a
                  href="https://learn.microsoft.com/en-us/graph/webhooks-with-resource-data?WT.mc_id=M365-MVP-5001530"
                  target="_blank"
                  rel="noreferrer"
                >
                  Set up change notifications that include resource data
                </a>
                .
              </p>
            }
          >
            Resource Data
          </InfoLabel>
        </Subtitle2>

        <div>
          <Checkbox
            label="Include resource data in change notifications"
            title="Include resource data in change notifications"
            onChange={(event) => {
              setIncludeResourceData(event.target.checked);
              ValidateOptionalChoices(
                event.target.checked,
                encryptionCertificate,
                encryptionCertificateId,
                supportedTLSVersion,
                notificationQueryOptions,
                lifecycleNotificationUrl,
                clientState
              );
            }}
          />
        </div>
        {includeResourceData && (
          <>
            <div>
              <Subtitle2 block>Encryption Certificate</Subtitle2>
              <Textarea
                className="optionalField"
                placeholder="Encryption Certificate Public Key"
                value={encryptionCertificate}
                onChange={(event) => {
                  setEncryptionCertificate(event.target.value);
                  ValidateOptionalChoices(
                    includeResourceData,
                    event.target.value,
                    encryptionCertificateId,
                    supportedTLSVersion,
                    notificationQueryOptions,
                    lifecycleNotificationUrl,
                    clientState
                  );
                }}
              />
            </div>

            <div>
              <Subtitle2 block>Encryption Certificate ID</Subtitle2>
              <Input
                className="optionalField"
                placeholder="Encryption Certificate ID"
                value={encryptionCertificateId}
                onChange={(event) => {
                  setEncryptionCertificateId(event.target.value);
                  ValidateOptionalChoices(
                    includeResourceData,
                    encryptionCertificate,
                    event.target.value,
                    supportedTLSVersion,
                    notificationQueryOptions,
                    lifecycleNotificationUrl,
                    clientState
                  );
                }}
              />
            </div>
          </>
        )}
        <Subtitle2 block>
          <InfoLabel
            info={
              <p>
                Optional. Specifies the latest version of Transport Layer
                Security (TLS) that the notification endpoint, specified by
                notificationUrl, supports. If not set, defaults to 1.2.
              </p>
            }
          >
            Set TLS Version
          </InfoLabel>
        </Subtitle2>

        <Dropdown
          placeholder="Set TLS Version"
          onOptionSelect={(event, data) => {
            setSupportedTLSVersion(data.optionValue);
            ValidateOptionalChoices(
              includeResourceData,
              encryptionCertificate,
              encryptionCertificateId,
              data.optionValue,
              notificationQueryOptions,
              lifecycleNotificationUrl,
              clientState
            );
          }}
        >
          {supportedTLSOptions.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Dropdown>
        {props.selectedItem.format.startsWith("/print") && (
          <div>
            <Subtitle2 block>
              {" "}
              <InfoLabel
                info={
                  <p>
                    OData query options for specifying value for the targeting
                    resource. Supported only for Universal Print Service. For
                    more information, see{" "}
                    <a
                      href="https://learn.microsoft.com/en-us/graph/universal-print-webhook-notifications"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Subscribe to change notifications from cloud printing APIs
                      using Microsoft Graph.
                    </a>
                  </p>
                }
              >
                Notification Query Options
              </InfoLabel>
            </Subtitle2>

            <Input
              className="optionalField"
              placeholder="Notification Query Options"
              value={notificationQueryOptions}
              onChange={(event) => {
                setNotificationQueryOptions(event.target.value);
                ValidateOptionalChoices(
                  includeResourceData,
                  encryptionCertificate,
                  encryptionCertificateId,
                  supportedTLSVersion,
                  event.target.value,
                  lifecycleNotificationUrl,
                  clientState
                );
              }}
            />
          </div>
        )}

        <div>
          <Subtitle2 block>
            <InfoLabel
              info={
                <p>
                  The URL of the endpoint that receives lifecycle notifications,
                  including subscriptionRemoved, reauthorizationRequired, and
                  missed notifications. Must be HTTPS. Optional, unless using
                  /teams resource is the expiry time is more than 1 hour.
                </p>
              }
            >
              Lifecycle Notification URL
            </InfoLabel>
          </Subtitle2>

          <Input
            className="optionalField"
            placeholder="The URL to receive lifecycle notifications"
            value={lifecycleNotificationUrl}
            onChange={(event) => {
              setLifecycleNotificationUrl(event.target.value);
              ValidateOptionalChoices(
                includeResourceData,
                encryptionCertificate,
                encryptionCertificateId,
                supportedTLSVersion,
                notificationQueryOptions,
                event.target.value,
                clientState
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
