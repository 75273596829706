import React, { useState } from "react";
import notificationData from "../services/data";
import OptionPicker from "./OptionPicker";
import InfoDisplay from "./InfoDisplay";
import {
  Button,
  Display,
  Divider,
  LargeTitle,
  Title3,
  Persona,
} from "@fluentui/react-components";
import RequiredChoices from "./RequiredChoices";
import OptionalChoices from "./OptionalChoices";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

export default function MainLayout() {
  // useEffect(() => {
  //   connect.contact((contact) => {
  //     setContact(contact);
  //     setIsCallConnected(true);
  //   });
  // }, []);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemInV1, setSelectedItemInV1] = useState(null);
  const [requiredChoices, setRequiredChoices] = useState(null);
  const [optionalChoices, setOptionalChoices] = useState(null);

  const onNotificationSelected = (data) => {
    console.log(data);
    const theItem = notificationData.find(
      (item) => item.format === data.optionValue
    );
    setSelectedItem(theItem);
    setSelectedItemInV1(theItem.isInV1);
  };

  const onRequiredChoicesUpdated = (data) => {
    setRequiredChoices(data);
    console.log(data);
  };

  const onOptionalChoicesUpdated = (data) => {
    setOptionalChoices(data);
    console.log(data);
  };

  return (
    <div className="container">
      <div className="headingContainer">
        <Display align="center">
          Microsoft Graph Change Notification Builder
        </Display>
      </div>
      <div className="headingContainer">
        <LargeTitle align="center">
          Quickly build syntactically correct{" "}
          <a
            href="https://learn.microsoft.com/graph/api/subscription-post-subscriptions?WT.mc_id=M365-MVP-5001530"
            target="_blank"
            rel="noreferrer"
          >
            subscription requests
          </a>{" "}
          for{" "}
          <a
            href="https://learn.microsoft.com/en-us/graph/overview?WT.mc_id=M365-MVP-5001530"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft Graph
          </a>
          .
        </LargeTitle>
      </div>
      <div
        className="centeredContent"
        style={{ marginTop: "5em", marginBottom: "2.5em" }}
      >
        <OptionPicker
          options={notificationData}
          notificationSelectedCallback={onNotificationSelected}
        />
      </div>
      <div className="ContentContainer">
        <div className="LeftContentContainer">
          {selectedItem && (
            <RequiredChoices
              selectedItem={selectedItem}
              onRequiredChoicesUpdated={onRequiredChoicesUpdated}
            />
          )}

          {selectedItem && (
            <div className="centeredLeftJustifyContent optionalChoicesContainer">
              <Accordion collapsible multiple>
                <AccordionItem>
                  <AccordionHeader>
                    Optional Additional Properties
                  </AccordionHeader>
                  <AccordionPanel>
                    <OptionalChoices
                      selectedItem={selectedItem}
                      onOptionalChoicesUpdated={onOptionalChoicesUpdated}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
          )}
        </div>
        <div className="RightContentContainer">
          {selectedItem && (
            <>
              <InfoDisplay
                selectedItem={selectedItem}
                selectedItemInV1={selectedItemInV1}
                requiredChoices={requiredChoices}
                optionalChoices={optionalChoices}
              />
              <Divider className="Divider" />
              <div className="StartAgainButtonContainer">
                <Button
                  className="AdditionalActionButton"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Start Again
                </Button>
                <a
                  href="https://github.com/tomorgan/graph-change-notification-builder/issues"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="AdditionalActionButton">
                    Something wrong?
                  </Button>
                </a>
              </div>
              <Divider className="Divider" />
            </>
          )}
        </div>
      </div>
      <Divider />
      <div class="LinkContainer">
        <div class="LeftLinkContainer">
          <div className="headingContainer">
            <LargeTitle>Useful Links</LargeTitle>
          </div>
          <div
            className=" centeredContent linksContainer"
            style={{ marginTop: "5em", marginBottom: "2.5em" }}
          >
            <ul>
              <li>
                <a
                  href="https://learn.microsoft.com/en-us/graph/api/resources/webhooks?WT.mc_id=M365-MVP-5001530"
                  target="_blank"
                  rel="noreferrer"
                >
                  Microsoft Graph API change notifications
                </a>
              </li>
              <li>
                <a
                  href="https://learn.microsoft.com/en-us/graph/change-notifications-delivery-webhooks?WT.mc_id=M365-MVP-5001530"
                  target="_blank"
                  rel="noreferrer"
                >
                  Receive change notifications through webhooks
                </a>
              </li>
              <li>
                <a
                  href="https://learn.microsoft.com/en-us/graph/api/resources/subscription?WT.mc_id=M365-MVP-5001530"
                  target="_blank"
                  rel="noreferrer"
                >
                  subscription resource type
                </a>
              </li>
              <li>
                <a
                  href="https://blog.thoughtstuff.co.uk/2020/10/using-microsoft-graph-change-notifications-dont-miss-out-on-broken-subscriptions-with-new-lifecycle-notifications/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Using Microsoft Graph Change Notifications? Don’t miss out on
                  broken subscriptions with NEW lifecycle notifications
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="RightLinkContainer">
          <div className="headingContainer">
            <LargeTitle>About this tool</LargeTitle>

            <div className="tomContainer">
              <Title3 align="start">Written by:</Title3>

              <Persona
                style={{ marginTop: "1em" }}
                name="Tom Morgan"
                secondaryText="Microsoft 365 Developer &amp; MVP"
                tertiaryText={
                  <a
                    href="https://thoughtstuff.co.uk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    thoughtstuff.co.uk
                  </a>
                }
                presence={{ status: "available" }}
                size="huge"
                href="https://thoughtstuff.co.uk"
                avatar={{
                  image: {
                    src: "/tom_small_round.png",
                  },
                }}
              />
            </div>

            <div
              className=" centeredContent linksContainer"
              style={{ marginTop: "2.5em", marginBottom: "2.5em" }}
            >
              <ul>
                <li>
                  <a
                    href="https://blog.thoughtstuff.co.uk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Why I built this. (coming soon)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.thoughtstuff.co.uk/start-here"
                    target="_blank"
                    rel="noreferrer"
                  >
                    More about me and what I do with Microsoft 365 development
                    tools.
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.paypal.com/donate/?business=F3T2CULG4BUH2&no_recurring=1&currency_code=GBP"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Saved you some time? Say thanks with a coffee or something
                    more!☕
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <p>
          This tool is provided as-is and without warranty. It is not associated
          with Microsoft. Copyright 2023 ThoughtStuff Ltd -{" "}
          <a href="https://thoughtstuff.co.uk">thoughtstuff.co.uk</a>
        </p>
      </div>
    </div>
  );
}
