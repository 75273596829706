const notificationData = [
  {
    category: "Cloud Printing",
    displayName: "Changes when a print job is ready to be downloaded",
    expiryMins: "4230",
    format: "/print/printers/{id}/jobs",
    isInV1: true,
    parent: "printer",
    quotaText:
      "Maximum subscription quotas: Per app (for all tenants combined): 50,000 total subscriptions, Per tenant (for all applications combined): 1000 total subscriptions across all apps, Per app and tenant combination: 100 total subscriptions. Not supported for Azure AD B2C tenants. NOTE: Creation and soft-deletion of groups will also trigger the updated changeType.",
  },
  {
    category: "Cloud Printing",
    displayName:
      "Changes when there is a valid job in the queue (jobStarted event):",
    expiryMins: "4230",
    format: "/print/printtaskdefinition/{id}/tasks",
    isInV1: true,
    parent: "printTaskDefinition",
    quotaText:
      "Maximum subscription quotas: Per app (for all tenants combined): 50,000 total subscriptions, Per tenant (for all applications combined): 1000 total subscriptions across all apps, Per app and tenant combination: 100 total subscriptions. Not supported for Azure AD B2C tenants. NOTE: Creation and soft-deletion of groups will also trigger the updated changeType.",
  },
  {
    category: "OneDrive (personal)",
    displayName: "Changes to content within the hierarchy of any folder:",
    expiryMins: "42300",
    format: "/users/{id}/drive/root",
    isInV1: true,
    parent: "driveItem",
    quotaText:
      "Maximum subscription quotas: Per app (for all tenants combined): 50,000 total subscriptions, Per tenant (for all applications combined): 1000 total subscriptions across all apps, Per app and tenant combination: 100 total subscriptions. Not supported for Azure AD B2C tenants. NOTE: Creation and soft-deletion of groups will also trigger the updated changeType.",
  },
  {
    category: "OneDrive (business)",
    displayName: "Changes to content within the hierarchy of the root folder:",
    expiryMins: "42300",
    format: "/drives/{id}/root",
    isInV1: true,
    parent: "driveItem",
    quotaText:
      "Maximum subscription quotas: Per app (for all tenants combined): 50,000 total subscriptions, Per tenant (for all applications combined): 1000 total subscriptions across all apps, Per app and tenant combination: 100 total subscriptions. Not supported for Azure AD B2C tenants. NOTE: Creation and soft-deletion of groups will also trigger the updated changeType.",
  },
  {
    category: "OneDrive (business)",
    displayName: "Changes to content within the hierarchy of the root folder:",
    expiryMins: "42300",
    format: "/users/{id}/drive/root",
    isInV1: true,
    parent: "driveItem",
    quotaText: "",
  },
  {
    category: "Groups",
    displayName: "Changes to all groups",
    expiryMins: "41760",
    format: "/groups",
    isInV1: true,
    parent: "group",
    quotaText: "",
  },
  {
    category: "Groups",
    displayName: "Changes to a specific group",
    expiryMins: "41760",
    format: "/groups/{id}",
    isInV1: true,
    parent: "group",
    quotaText:
      "A maximum of 1,000 active subscriptions per mailbox for all applications is allowed.",
  },
  {
    category: "Groups",
    displayName: "Changes to owners of a specific group",
    expiryMins: "41760",
    format: "/groups/{id}/owners",
    isInV1: true,
    parent: "group",
    quotaText:
      "A maximum of 1,000 active subscriptions per mailbox for all applications is allowed.",
  },
  {
    category: "Groups",
    displayName: "Changes to members of a specific group",
    expiryMins: "41760",
    format: "/groups/{id}/members",
    isInV1: true,
    parent: "group",
    quotaText:
      "A maximum of 1,000 active subscriptions per mailbox for all applications is allowed.",
  },
  {
    category: "SharePoint",
    displayName: "Changes to content within the list",
    expiryMins: "42300",
    format: "/sites/{site-id}/lists/{list-id}",
    isInV1: true,
    parent: "list",
    quotaText:
      "A maximum of 1,000 active subscriptions per mailbox for all applications is allowed.",
  },
  {
    category: "Group Conversation",
    displayName: "Changes to a group's conversations",
    expiryMins: "4230",
    format: "groups/{id}/conversations",
    isInV1: true,
    parent: "conversation",
    quotaText: "",
  },
  {
    category: "Outlook",
    displayName: "Changes to all messages in a user's mailbox",
    expiryMins: "4230",
    format: "/users/{id}/messages",
    isInV1: true,
    parent: "message",
    quotaText: "",
  },
  {
    category: "Outlook",
    displayName: "Changes to all messages in my mailbox",
    expiryMins: "4230",
    format: "/me/messages",
    isInV1: true,
    parent: "message",
    quotaText:
      "A maximum of 1,000 active subscriptions per mailbox for all applications is allowed.",
  },
  {
    category: "Outlook",
    displayName: "Changes to messages in a user's Inbox",
    expiryMins: "4230",
    format: "/users/{id}/mailFolders('inbox')/messages",
    isInV1: true,
    parent: "message",
    quotaText:
      "A maximum of 1,000 active subscriptions per mailbox for all applications is allowed.",
  },
  {
    category: "Outlook",
    displayName: "Changes to messages in my Inbox",
    expiryMins: "4230",
    format: "/me/mailFolders('inbox')/messages",
    isInV1: true,
    parent: "message",
    quotaText:
      "For more information, see Security API alerts: https://learn.microsoft.com/en-us/graph/api/resources/security-api-overview",
  },
  {
    category: "Outlook",
    displayName: "Changes to all events in a user's mailbox",
    expiryMins: "4230",
    format: "/users/{id}/events",
    isInV1: true,
    parent: "event",
    quotaText:
      "For more information, see Security API alerts: https://learn.microsoft.com/en-us/graph/api/resources/security-api-overview",
  },
  {
    category: "Outlook",
    displayName: "Changes to all events in my mailbox",
    expiryMins: "4230",
    format: "/me/events",
    isInV1: true,
    parent: "event",
    quotaText:
      "Maximum subscription quotas: Per organization: 100 total subscriptions.",
  },
  {
    category: "Outlook",
    displayName: "Changes to all personal contacts in a user's mailbox",
    expiryMins: "4230",
    format: "/users/{id}/contacts",
    isInV1: true,
    parent: "contact",
    quotaText:
      "Maximum subscription quotas: Per app and online-meeting combination: 1. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Outlook",
    displayName: "Change to all personal contacts in my mailbox",
    expiryMins: "4230",
    format: "/me/contacts",
    isInV1: true,
    parent: "contact",
    quotaText:
      "Maximum subscription quotas: Per app and online-meeting combination: 1. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Security",
    displayName: "Changes to a specific alert",
    expiryMins: "43200",
    format: "/security/alerts/{id}",
    isInV1: true,
    parent: "alert",
    quotaText:
      "Maximum subscription quotas: Per app and online-meeting combination: 1. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Security",
    displayName: "Changes to filtered alerts",
    expiryMins: "43200",
    format: "/security/alerts/?$filter={parameters}",
    isInV1: true,
    parent: "alert",
    quotaText:
      "Maximum subscription quotas: Per app and online-meeting combination: 1. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Meetings and Calling",
    displayName: "Changes to all call records",
    expiryMins: "4230",
    format: "/communications/callRecords",
    isInV1: true,
    parent: "callRecord",
    quotaText:
      "Maximum subscription quotas: Per app and chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Meetings and Calling",
    displayName: "Any recording becomes available in the tenant",
    expiryMins: "60",
    format: "/communications/onlineMeetings/getAllRecordings",
    isInV1: false,
    parent: "callRecording",
    quotaText:
      "Maximum subscription quotas: Per app and chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Meetings and Calling",
    displayName: "Any recording becomes available for a specific meeting",
    expiryMins: "60",
    format: "/communications/onlineMeetings/{onlineMeetingId}/recordings",
    isInV1: false,
    parent: "callRecording",
    quotaText:
      "Maximum subscription quotas: Per app and chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Meetings and Calling",
    displayName: "Any transcript becomes available in the tenant",
    expiryMins: "60",
    format: "/communications/onlineMeetings/getAllTranscripts",
    isInV1: false,
    parent: "callTranscript",
    quotaText:
      "Maximum subscription quotas: Per app and channel or chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Meetings and Calling",
    displayName: "Any transcript becomes available for a specific meeting",
    expiryMins: "60",
    format: "/communications/onlineMeetings/{onlineMeetingId}/transcripts",
    isInV1: false,
    parent: "callTranscript",
    quotaText:
      "Maximum subscription quotas: Per app and channel or chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to any chat in the tenant",
    expiryMins: "60",
    format: "/chats",
    isInV1: true,
    parent: "chat",
    quotaText:
      "Maximum subscription quotas: Per app and channel or chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to a specific chat",
    expiryMins: "60",
    format: "/chats/{id}",
    isInV1: true,
    parent: "chat",
    quotaText:
      "Maximum subscription quotas: Per app and channel or chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName:
      "Changes to all chats in an organization where a particular Teams app is installed",
    expiryMins: "60",
    format: "/appCatalogs/teamsApps/{id}/installedToChats",
    isInV1: true,
    parent: "chat",
    quotaText:
      "Maximum subscription quotas: Per app and channel or chat combination: 1 subscription. Per user (for subscriptions tracking chat messages in all chats the user is part of): 10 subscriptions. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to chat messages in a specific channel",
    expiryMins: "60",
    format: "/teams/{id}/channels/{id}/messages",
    isInV1: true,
    parent: "chatMessage",
    quotaText:
      "Maximum subscription quotas: Per app and channel or chat combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "",
    expiryMins: "60",
    format: "/teams/getAllMessages",
    isInV1: true,
    parent: "chatMessage",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to chat messages in a specific chat",
    expiryMins: "60",
    format: "/chats/{id}/messages",
    isInV1: true,
    parent: "chatMessage",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to chat messages in all chats",
    expiryMins: "60",
    format: "/chats/getAllMessages",
    isInV1: true,
    parent: "chatMessage",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName:
      "Changes to chat messages in all chats a particular user is part of",
    expiryMins: "60",
    format: "/users/{id}/chats/getAllMessages",
    isInV1: true,
    parent: "chatMessage",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName:
      "Changes to chat messages for all chats in an organization where a particular Teams app is installed",
    expiryMins: "60",
    format: "/appCatalogs/teamsApps/{id}/installedToChats/getAllMessages",
    isInV1: true,
    parent: "chatMessage",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to channels in all teams",
    expiryMins: "60",
    format: "/teams/getAllChannels",
    isInV1: true,
    parent: "channel",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to channel in a specific team",
    expiryMins: "60",
    format: "/teams/{id}/channels",
    isInV1: true,
    parent: "channel",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to membership in a specific team",
    expiryMins: "60",
    format: "/teams/{id}/members",
    isInV1: true,
    parent: "conversationMember",
    quotaText: "",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to membership in all channels under a specific team",
    expiryMins: "60",
    format: "/teams/{id}/channels/getAllMembers",
    isInV1: true,
    parent: "conversationMember",
    quotaText: "",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to membership in a specific chat:",
    expiryMins: "60",
    format: "/chats/{id}/members",
    isInV1: true,
    parent: "conversationMember",
    quotaText: "",
  },
  {
    category: "Teams - Chats and Channels",
    displayName:
      "Changes to membership for all chats in an organization where a particular Teams app is installed:",
    expiryMins: "60",
    format: "/appCatalogs/teamsApps/{id}/installedToChats/getAllMembers",
    isInV1: true,
    parent: "conversationMember",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Chats and Channels",
    displayName: "Changes to membership in all chats",
    expiryMins: "60",
    format: "/teams/getAllMembers",
    isInV1: true,
    parent: "conversationMember",
    quotaText:
      "Maximum subscription quotas: Per app and team combination: 1 subscription. Per organization: 10,000 total subscriptions.",
  },
  {
    category: "Teams - Meetings and Calling",
    displayName: "Changes to an online meeting",
    expiryMins: "4320",
    format: "/communications/onlineMeetings/?$filter=JoinWebUrl eq {joinWebUrl",
    isInV1: false,
    parent: "onlineMeeting",
    quotaText: "",
  },
  {
    category: "Teams",
    displayName: "Changes to a single user's presence",
    expiryMins: "60",
    format: "/communications/presences/{id}",
    isInV1: true,
    parent: "presence",
    quotaText:
      "Maximum subscription quotas: Per app (for all tenants combined): 50,000 total subscriptions. Per tenant (for all applications combined): 1000 total subscriptions across all apps. Per app and tenant combination: 100 total subscriptions. Not supported for personal Microsoft accounts like outlook.com. Not supported for Azure AD B2C tenants. NOTE: Creation and soft-deletion of users will also trigger the updated changeType.",
  },
  {
    category: "Teams",
    displayName: "Changes to multiple user presences",
    expiryMins: "60",
    format: "/communications/presences?$filter=id in ({id},{id}...)",
    isInV1: true,
    parent: "presence",
    quotaText:
      "Maximum subscription quotas: Per app (for all tenants combined): 50,000 total subscriptions. Per tenant (for all applications combined): 1000 total subscriptions across all apps. Per app and tenant combination: 100 total subscriptions. Not supported for personal Microsoft accounts like outlook.com. Not supported for Azure AD B2C tenants. NOTE: Creation and soft-deletion of users will also trigger the updated changeType.",
  },
  {
    category: "Teams",
    displayName: "Changes to any team in the tenant",
    expiryMins: "4320",
    format: "/team",
    isInV1: true,
    parent: "team",
    quotaText: "",
  },
  {
    category: "Teams",
    displayName: "Changes to a specific team",
    expiryMins: "4320",
    format: "/teams/{id}",
    isInV1: true,
    parent: "team",
    quotaText: "",
  },
  {
    category: "ToDo",
    displayName: "Changes to all task in a specific task list",
    expiryMins: "4230",
    format: "/me/todo/lists/{todoTaskListId}/tasks",
    isInV1: true,
    parent: "todoTask",
    quotaText: "",
  },
  {
    category: "Users",
    displayName: "Changes to all users",
    expiryMins: "41760",
    format: "/users",
    isInV1: true,
    parent: "user",
    quotaText: "",
  },
  {
    category: "Users",
    displayName: "Changes to a specific user",
    expiryMins: "41760",
    format: "/users/{id}",
    isInV1: true,
    parent: "user",
    quotaText: "",
  },
];

export default notificationData;
