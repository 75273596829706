import { CopyBlock, dracula } from "react-code-blocks";
import React from "react";
import { Switch } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";

export default function InfoDisplay(props) {
  const [useBetaEndpoint, setUseBetaEndpoint] = React.useState(
    !props.selectedItemInV1
  );

  React.useEffect(() => {
    console.log("useEffect");
    if (!props.selectedItemInV1) {
      setUseBetaEndpoint(true);
    } else {
      setUseBetaEndpoint(false);
    }
  }, [props.selectedItemInV1]);

  return (
    <div className="infoDisplayContainer">
      <h2>Create a subscription for: {props.selectedItem.displayName}</h2>

      {!props.requiredChoices && (
        <>
          <Alert intent="info">
            Code will be shown once all required choices are made.
          </Alert>
        </>
      )}

      {props.requiredChoices && (
        <>
          <Switch
            label="use /beta endpoint"
            value={useBetaEndpoint}
            checked={useBetaEndpoint}
            disabled={!props.selectedItem.isInV1}
            onChange={(event) => {
              setUseBetaEndpoint(event.target.checked);
            }}
          />
          <div className="methodHeader">
            <p>
              POST https://graph.microsoft.com/
              {useBetaEndpoint ? "beta" : "v1.0"}/subscriptions
            </p>
            <p> Content-Type: application/json</p>
          </div>

          <CopyBlock
            language={"json"}
            theme={dracula}
            text={`{
            "changeType": "${props.requiredChoices.changeType}",
            "notificationUrl": "${props.requiredChoices.notificationUrl}",
            "resource": "${props.selectedItem.format}",
            "expirationDateTime": "${props.requiredChoices.expirationTime}"${
              props.optionalChoices?.clientState
                ? `,
            "clientState": "${props.optionalChoices.clientState}"`
                : ""
            }${
              props.optionalChoices?.creatorId
                ? `,
            "creatorId": "${props.optionalChoices.creatorId}"`
                : ""
            }${
              props.optionalChoices?.includeResourceData
                ? `,
            "includeResourceData": "${props.optionalChoices.includeResourceData}"`
                : ""
            }${
              props.optionalChoices?.encryptionCertificate
                ? `,
            "encryptionCertificate": "${props.optionalChoices.encryptionCertificate}"`
                : ""
            }${
              props.optionalChoices?.encryptionCertificateId
                ? `,
            "encryptionCertificateId": "${props.optionalChoices.encryptionCertificateId}"`
                : ""
            }${
              props.optionalChoices?.supportedTLSVersion
                ? `,
            "latestSupportedTlsVersion": "${props.optionalChoices.supportedTLSVersion}"`
                : ""
            }${
              props.selectedItem.format.startsWith("/print") &&
              props.optionalChoices?.notificationQueryOptions
                ? `,
            "notificationQueryOptions": "${props.optionalChoices.notificationQueryOptions}"`
                : ""
            }${
              props.optionalChoices?.lifecycleNotificationUrl
                ? `,
            "lifecycleNotificationUrl": "${props.optionalChoices.lifecycleNotificationUrl}"`
                : ""
            }
}`}
          />

          {useBetaEndpoint && (
            <Alert
              className="Alert"
              onClick={() => {
                window.open(
                  "https://learn.microsoft.com/en-us/graph/api/overview?view=graph-rest-beta&preserve-view=true",
                  "_blank"
                );
              }}
              intent="warning"
              action="Read more"
            >
              You are using a /beta endpoint. This is not recommended for
              production use.
            </Alert>
          )}

          {props.selectedItem.quotaText && (
            <Alert className="Alert" intent="info">
              {props.selectedItem.quotaText}
            </Alert>
          )}
        </>
      )}
    </div>
  );
}
